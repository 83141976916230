import React from 'react';
import PropTypes from 'prop-types';
import PartialTable from 'components/partials/stock-location/PartialTable';
import { Link } from 'react-router-dom';

const columns = [
  {
    accessor: 'barcode',
    Header: 'Bin Barcode'
  },
  {
    accessor: 'type',
    Header: 'Type'
  },
  {
    accessor: 'status',
    Header: 'Status'
  },
  {
    accessor: 'locationBarcode',
    Header: 'Location'
  },
  {
    accessor: 'action',
    Header: 'Action',
    Cell: rowData => {
      const { id } = rowData.row.original;
      return (
        <Link to={`/stock-location/bin-management/bin-manager?id=${id}`}>
          Edit
        </Link>
      );
    }
  }
];
const BinTable = ({
  data,
  paging,
  total,
  nextPage,
  previousPage,
  gotoPage
}) => {
  return (
    <>
      <PartialTable
        data={data}
        columns={columns}
        paging={paging}
        total={total}
        nextPage={nextPage}
        previousPage={previousPage}
        gotoPage={gotoPage}
        // view={'/stock-location/bin-management/bin-manager?id='}
      />
    </>
  );
};

BinTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  paging: PropTypes.object,
  total: PropTypes.number,
  nextPage: PropTypes.func,
  previousPage: PropTypes.func,
  gotoPage: PropTypes.func
};

export default BinTable;

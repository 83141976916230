import React from 'react';
import PropTypes from 'prop-types';
import PartialTable from 'components/partials/stock-location/PartialTable';
import { Link } from 'react-router-dom';
import JsBarcode from 'jsbarcode';

const columns = [
  { accessor: 'site', Header: 'Site' },
  { accessor: 'areaCode', Header: 'Area' },
  { accessor: 'rack', Header: 'X' },
  { accessor: 'shelf', Header: 'Y' },
  { accessor: 'location', Header: 'Z' },
  { accessor: 'barcode', Header: 'Barcode' },
  { accessor: 'updated', Header: 'Updated', type: 'date' },
  { accessor: 'updatedBy', Header: 'Updated By' },
  { accessor: 'status', Header: 'Status' },
  { accessor: 'notes', Header: 'Notes' },
  {
    accessor: 'action',
    Header: 'Action',
    Cell: rowData => {
      const { id, barcode } = rowData.row.original;
      const parts = barcode.split(' ');
      const part2 = parts[parts.length - 1]
      const printLabel = () => {
        const printWindow = window.open('', '', 'height=400,width=1000');
        printWindow.document.write('<html><head><title>Print Label</title>');
        printWindow.document.write(
          '<style>@page { size: auto; margin: 0mm; } ' +
          'body { margin: 0; padding: 0; display: flex; justify-content: center; align-items: center; height: 100vh; } ' +
          'svg { width: 100%; height: auto; } ' +
          '#sub { text-align: center; font-size: 24px; font-weight: bold; }' +
          '</style>'
        );
        printWindow.document.write('</head><body>');
        printWindow.document.write(`<div style="display: flex; flex-direction: column; align-items: center;">`);
        printWindow.document.write(`<svg id="barcode"></svg>`);
        printWindow.document.write(`<div id="sub">${barcode}</div>`);
        printWindow.document.write('</div></body></html>');
        printWindow.document.close();
      
        printWindow.onload = function () {
          JsBarcode(printWindow.document.getElementById('barcode'), part2, {
            format: 'CODE128',
            width: 5,
            height: 200,
            displayValue: true,
            fontOptions: 'bold',
            fontSize: 20,
            textMargin: 5
          });
      
          printWindow.print();
        };
      
        printWindow.onafterprint = function () {
          printWindow.close();
        };
      };
      

      return (
        <div>
          <Link
            to={`/stock-location/location-management/location-manager?id=${id}`}
            style={{ display: 'block', marginBottom: '2px' }}
          >
            <label>Edit</label>
          </Link>
          <label
            onClick={printLabel}
            style={{ display: 'block', color: 'blue', cursor: 'pointer' }}
          >
            Print label
          </label>
        </div>
      );
    }
  }
];

const LocationTable = ({
  data,
  paging,
  total,
  nextPage,
  previousPage,
  gotoPage
}) => {
  return (
    <>
      <PartialTable
        data={data}
        columns={columns}
        paging={paging}
        total={total}
        nextPage={nextPage}
        previousPage={previousPage}
        gotoPage={gotoPage}
      />
    </>
  );
};

LocationTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  paging: PropTypes.object,
  total: PropTypes.number,
  nextPage: PropTypes.func,
  previousPage: PropTypes.func,
  gotoPage: PropTypes.func
};

export default LocationTable;
